var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block"
  }, [_c('h5', {
    staticClass: "title is-5"
  }, [_vm._v(_vm._s(_vm.question.title))]), _c('p', [_vm._v(_vm._s(_vm.question.description))]), _c('b-field', {
    key: _vm.question.id
  }, [_c('b-input', {
    attrs: {
      "value": "Respuesta"
    },
    model: {
      value: _vm.answer,
      callback: function callback($$v) {
        _vm.answer = $$v;
      },
      expression: "answer"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }