<template>
<div class="block">
  <b-field :label="question.title">
    <b-input maxlength="1000" type="textarea" v-model="answer"></b-input>
  </b-field>
  <b-field v-if="isPreview" type="is-danger"  label="Comentar:">
    <b-input size="is-small" :disabled="false" placeholder="Comentario del supervisor" maxlength="500" type="textarea"
             v-model="comment"></b-input>
  </b-field>
</div>
</template>

<script>
export default {
  props: {
    isPreview: {
      required: true,
      type: Boolean
    },
    question: {
      type: Object,
      required: true
    }
  },
  methods: {
    saveAnswer () {
      this.$emit('answer', {
        questionId: this.question.id,
        answer: this.answer
      })
    }
  },
  data () {
    return {
      answer: '',
      comment: 'Error en la respuesta por los siguientes motivos: ...'
    }
  },
  created () {
    this.$root.$on('getAnswers', this.saveAnswer)
    if (this.question.answer) {
      this.answer = this.lodash.cloneDeep(this.question.answer)
    }
  },
  name: 'QuestionTypeTextArea'
}
</script>

<style scoped>

</style>
