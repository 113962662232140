var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block"
  }, [_c('h5', {
    staticClass: "title is-5"
  }, [_vm._v(_vm._s(_vm.question.title))]), _c('p', [_vm._v(_vm._s(_vm.question.description))]), _c('b-field', {
    key: _vm.question.id,
    attrs: {
      "label": "Subir archivo",
      "type": {
        'is-danger': _vm.errors[0]
      },
      "message": _vm.errors
    }
  }, [_vm.answer === '' && !_vm.media ? _c('b-upload', {
    attrs: {
      "loading": _vm.uploadingMedia,
      "multiple:": "",
      "false": "",
      "accept": "application/pdf,image/jpeg,image/png,video/mp4,video/x-m4v,video/*",
      "drag-drop": ""
    },
    on: {
      "input": _vm.handleUploadMedia
    },
    model: {
      value: _vm.media,
      callback: function callback($$v) {
        _vm.media = $$v;
      },
      expression: "media"
    }
  }, [_c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "content has-text-centered"
  }, [_c('p', [_c('b-icon', {
    attrs: {
      "icon": "upload",
      "size": "is-large"
    }
  })], 1), _c('p', [_vm._v("Arrastra o haz click para subir el archivo (máx. 10Mb)")]), _c('ul', {
    staticClass: "m-0 has-text-grey",
    staticStyle: {
      "list-style": "none"
    }
  }, [_c('li', [_vm._v("Imagen: jpg / png")]), _c('li', [_vm._v("Documento: pdf")]), _c('li', [_vm._v("Video: mov / mp4")])])])])]) : _vm._e()], 1), _vm.fileName ? _c('div', [_vm.isImage ? _c('b-image', {
    attrs: {
      "src": _vm.answer
    }
  }) : _c('div', {
    staticClass: "block"
  }, [_c('a', {
    attrs: {
      "href": _vm.answer,
      "target": "_blank"
    }
  }, [_vm._v("Ver archivo")])]), _c('b-tag', {
    attrs: {
      "type": "is-primary",
      "closable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.deleteFiles();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.fileName) + " ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }