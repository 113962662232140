var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block"
  }, [_c('h5', {
    staticClass: "title is-5",
    staticStyle: {
      "position": "relative"
    }
  }, [_vm._v(_vm._s(_vm.question.title))]), _c('p', [_vm._v(_vm._s(_vm.question.description))]), _c('b-field', [_c('b-select', {
    attrs: {
      "placeholder": "Seleccione una opción",
      "expanded": ""
    },
    model: {
      value: _vm.answer,
      callback: function callback($$v) {
        _vm.answer = $$v;
      },
      expression: "answer"
    }
  }, _vm._l(_vm.question.options, function (option, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": option.id
      }
    }, [_vm._v(_vm._s(option.name))]);
  }), 0)], 1), _vm.isPreview ? _c('b-field', {
    staticClass: "mt-3",
    attrs: {
      "type": "is-warning",
      "horizontal": "",
      "label": "Supervisor:"
    }
  }, [_c('b-input', {
    attrs: {
      "type": "textarea",
      "size": "is-small",
      "disabled": true,
      "placeholder": "Comentario del supervisor",
      "maxlength": "200"
    },
    model: {
      value: _vm.comment,
      callback: function callback($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }