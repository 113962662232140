<template>
  <div class="block">
    <h5 class="title is-5">{{ question.title }}</h5>
    <p>{{ question.description }}</p>
    <b-field label="Subir archivo" :key="question.id" :type="{ 'is-danger': errors[0]}"
             :message="errors">
      <b-upload v-if="answer ==='' && !media" v-model="media"
                :loading="uploadingMedia"
                multiple: false
                @input="handleUploadMedia"
                accept="application/pdf,image/jpeg,image/png,video/mp4,video/x-m4v,video/*"
                drag-drop>
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon
                icon="upload"
                size="is-large">
              </b-icon>
            </p>
            <p>Arrastra o haz click para subir el archivo (máx. 10Mb)</p>
            <ul class="m-0 has-text-grey" style="list-style: none">
              <li>Imagen: jpg / png</li>
              <li>Documento: pdf</li>
              <li>Video: mov / mp4</li>
            </ul>
          </div>
        </section>
      </b-upload>
    </b-field>
    <div v-if="fileName">
      <b-image v-if="isImage" :src="answer"></b-image>
      <div v-else class="block">
        <a :href="answer" target="_blank">Ver archivo</a>
      </div>
      <b-tag type="is-primary" closable @close="deleteFiles()">
        {{ fileName }}
      </b-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionTypeMedia',
  props: {
    question: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    questionnaireId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      answer: '',
      errors: [],
      media: null,
      fileName: null,
      fileType: null,
      uploadingMedia: false
    }
  },
  created () {
    this.$root.$on('getAnswers', this.saveAnswer)
    if (this.question.answer) {
      this.answer = this.lodash.cloneDeep(this.question.answer)
      const aux = this.answer.split('/')
      this.fileName = aux[aux.length - 1]
      const auxType = this.fileName.split('.')
      this.fileType = auxType[auxType.length - 1]
    }
  },
  methods: {
    getMediaType (media) {
      switch (media.type) {
        case 'image/jpeg':
          this.fileType = 'jpg'
          break
        case 'image/png':
          this.fileType = 'png'
          break
      }
    },
    handleUploadMedia () {
      this.errors = []
      if (this.media.size > 1024 * 1024 * 10) {
        this.errors.push('El archivo supera el tamaño máximo (10 MB)')
        this.media = null
      }
      if (!this.errors || this.errors.length === 0) {
        this.fileName = this.media.name
        this.getMediaType(this.media)
        const formData = new FormData()
        formData.append('file', this.media)
        formData.append('questionId', this.question.id)
        formData.append('userId', this.user.id.toString())
        formData.append('questionnaireId', this.questionnaireId.toString())
        formData.append('companyId', this.user.company.id)
        const headers = { 'Content-Type': 'multipart/form-data' }
        this.uploadingMedia = true
        this.axios.post(process.env.VUE_APP_API_UPLOAD_MEDIA, formData, {
          headers: headers
        }).then(response => {
          this.answer = response.data
        }
        ).catch(err => {
          console.error('Error al subir el archivo', err)
          this.$notify.error({
            title: 'Error',
            message: 'El archivo no se admite'
          })
        }).finally(() => {
          this.uploadingMedia = false
        })
      }
    },
    saveAnswer () {
      this.$emit('answer', {
        questionId: this.question.id,
        answer: this.answer
      })
    },
    deleteFiles () {
      this.uploadingMedia = true
      this.axios.delete(process.env.VUE_APP_API_DELETE_MEDIA, {
        params: {
          resourceUrl: this.answer
        }
      }).then(() => {
        this.media = null
        this.answer = ''
        this.fileName = null
        this.fileType = null
      }).finally(() => {
        this.uploadingMedia = false
      })
    }
  },
  computed: {
    isImage () {
      return this.fileType && (this.fileType.toLowerCase() === 'jpg' || this.fileType.toLowerCase() === 'png' ||
        this.fileType.toLowerCase() === 'jpeg')
    }
  }
}
</script>

<style lang="scss">

</style>
