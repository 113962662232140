var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block"
  }, [_c('b-field', {
    attrs: {
      "label": _vm.question.title
    }
  }, [_c('b-input', {
    attrs: {
      "maxlength": "1000",
      "type": "textarea"
    },
    model: {
      value: _vm.answer,
      callback: function callback($$v) {
        _vm.answer = $$v;
      },
      expression: "answer"
    }
  })], 1), _vm.isPreview ? _c('b-field', {
    attrs: {
      "type": "is-danger",
      "label": "Comentar:"
    }
  }, [_c('b-input', {
    attrs: {
      "size": "is-small",
      "disabled": false,
      "placeholder": "Comentario del supervisor",
      "maxlength": "500",
      "type": "textarea"
    },
    model: {
      value: _vm.comment,
      callback: function callback($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }