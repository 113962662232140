var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoadingState ? _c('validation-observer', {
    ref: "observer",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "hero-head"
        }, [_c('div', {
          staticClass: "navbar"
        }, [_c('div', {
          staticClass: "container is-justify-content-center  mt-6"
        }, [_c('div', {
          staticClass: "block"
        }, [_c('h1', {
          staticClass: "title is-1"
        }, [_vm._v(_vm._s(_vm.questionnaire.name))])])])])]), _c('div', {
          staticClass: "hero-body"
        }, [_c('b-steps', {
          staticClass: "container is-fluid",
          attrs: {
            "animated": "",
            "rounded": "",
            "has-navigation": "",
            "icon-prev": "chevron-left",
            "icon-next": "chevron-right",
            "label-position": "bottom",
            "mobile-mode": "minimalist"
          },
          model: {
            value: _vm.activeSection,
            callback: function callback($$v) {
              _vm.activeSection = $$v;
            },
            expression: "activeSection"
          }
        }, _vm._l(_vm.questionnaire.sections, function (section, i) {
          return _c('b-step-item', {
            key: i,
            attrs: {
              "step": i + 1,
              "clickable": true
            }
          }, [_c('div', {
            staticClass: "container has-text-left mt-5"
          }, [_c('div', {
            staticClass: "block"
          }, [_c('h3', {
            staticClass: "title is-3"
          }, [_vm._v(_vm._s(section.name))])]), section.help ? _c('b-collapse', {
            staticClass: "panel",
            attrs: {
              "aria-id": "contentIdForA11y2",
              "animation": "slide"
            },
            scopedSlots: _vm._u([{
              key: "trigger",
              fn: function fn() {
                return [_c('div', {
                  staticClass: "panel-heading",
                  attrs: {
                    "role": "button",
                    "aria-controls": "contentIdForA11y2"
                  }
                }, [_c('strong', [_vm._v("Ayuda")])])];
              },
              proxy: true
            }], null, true),
            model: {
              value: _vm.openHelp,
              callback: function callback($$v) {
                _vm.openHelp = $$v;
              },
              expression: "openHelp"
            }
          }, [_c('quill-editor', {
            attrs: {
              "options": _vm.quillOptions,
              "disabled": ""
            },
            model: {
              value: section.help,
              callback: function callback($$v) {
                _vm.$set(section, "help", $$v);
              },
              expression: "section.help"
            }
          })], 1) : _vm._e(), _c('div', {
            staticClass: "columns is-multiline"
          }, _vm._l(section.questions, function (question) {
            return _c('div', {
              key: question.id,
              class: _vm.handleDisposition(section.disposition)
            }, [question.type.id === 1 ? _c('question-type-text', {
              attrs: {
                "question": question,
                "isPreview": _vm.isPreview
              },
              on: {
                "answer": _vm.handleAnswer
              }
            }) : _vm._e(), question.type.id === 2 ? _c('question-type-radio', {
              attrs: {
                "question": question,
                "is-preview": _vm.isPreview
              },
              on: {
                "answer": _vm.handleAnswer
              }
            }) : _vm._e(), question.type.id === 3 ? _c('question-type-text-area', {
              attrs: {
                "question": question,
                "is-preview": _vm.isPreview
              },
              on: {
                "answer": _vm.handleAnswer
              }
            }) : _vm._e(), question.type.id === 4 ? _c('question-type-media', {
              attrs: {
                "question": question,
                "questionnaire-id": _vm.questionnaireId,
                "user": _vm.user
              },
              on: {
                "answer": _vm.handleAnswer
              }
            }) : _vm._e()], 1);
          }), 0)], 1)]);
        }), 1)], 1), _c('div', {
          staticClass: "hero-foot"
        }, [!_vm.isReview ? _c('div', {
          staticClass: "buttons is-right p-6"
        }, [_c('b-tooltip', {
          attrs: {
            "label": "Deshabilitado en previsualización",
            "active": _vm.isPreview,
            "type": "is-dark",
            "position": "is-top"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "is-dark",
            "disabled": _vm.isPreview,
            "loading": _vm.waitingForResponses
          },
          on: {
            "click": _vm.saveQuestionnaire
          }
        }, [_vm._v(" Guardar Borrador ")])], 1), _c('b-tooltip', {
          attrs: {
            "label": "Deshabilitado en previsualización",
            "active": _vm.isPreview,
            "type": "is-dark",
            "position": "is-top"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "is-primary",
            "loading": _vm.waitingForResponses,
            "disabled": _vm.isPreview
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.sendQuestionnaire);
            }
          }
        }, [_vm._v("Enviar ")])], 1)], 1) : _vm._e(), _vm.isReview ? _c('div', {
          staticClass: "buttons is-right p-6"
        }, [_c('b-tooltip', {
          attrs: {
            "label": "Deshabilitado en previsualización",
            "active": _vm.isPreview,
            "type": "is-dark",
            "position": "is-top"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "is-dark",
            "disabled": _vm.isPreview,
            "loading": _vm.waitingForResponses
          },
          on: {
            "click": _vm.cancelReview
          }
        }, [_vm._v("Volver ")])], 1), _c('b-tooltip', {
          attrs: {
            "label": "Deshabilitado en previsualización",
            "active": _vm.isPreview,
            "type": "is-dark",
            "position": "is-top"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "is-primary",
            "loading": _vm.waitingForResponses,
            "disabled": _vm.isPreview
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.approveQuestionnaire);
            }
          }
        }, [_vm._v("Aprobar ")])], 1)], 1) : _vm._e()])];
      }
    }], null, false, 667531548)
  }) : _c('b-loading', {
    attrs: {
      "is-full-page": false,
      "v-model": _vm.isLoadingState,
      "active": _vm.isLoadingState,
      "can-cancel": false
    }
  }, [_c('div', {
    staticClass: "is-flex is-flex-direction-column is-align-items-center"
  }, [_c('h2', {
    staticClass: "title is-2",
    staticStyle: {
      "color": "black !important"
    }
  }, [_vm._v(_vm._s(_vm.savingQuestionnaire ? 'Procesando ficha de análisis' : 'Cargando ficha de análisis'))]), _c('b-icon', {
    attrs: {
      "pack": "fas",
      "icon": "sync-alt",
      "size": "is-large",
      "custom-class": "fa-spin"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }