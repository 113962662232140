<template>
  <validation-observer v-if="!isLoadingState" tag="div" ref="observer"
                       v-slot="{handleSubmit}">
    <div class="hero-head">
      <div class="navbar">
        <div class="container is-justify-content-center  mt-6">
          <div class="block">
            <h1 class="title is-1">{{ questionnaire.name }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-body">
      <b-steps
        v-model="activeSection"
        animated
        rounded
        has-navigation
        icon-prev="chevron-left"
        icon-next="chevron-right"
        label-position="bottom"
        mobile-mode="minimalist" class="container is-fluid">
        <b-step-item v-for="(section, i) in questionnaire.sections" :step="i + 1" :clickable="true"
                     :key="i">
          <div class="container has-text-left mt-5">
            <div class="block">
              <h3 class="title is-3">{{ section.name }}</h3>
            </div>
            <b-collapse
              v-if="section.help"
              aria-id="contentIdForA11y2"
              class="panel"
              animation="slide"
              v-model="openHelp">
              <template #trigger>
                <div
                  class="panel-heading"
                  role="button"
                  aria-controls="contentIdForA11y2">
                  <strong>Ayuda</strong>
                </div>
              </template>
              <quill-editor v-model="section.help" :options="quillOptions" disabled></quill-editor>
            </b-collapse>
            <div class="columns is-multiline">
              <div v-for="(question) in section.questions"
                   :class="handleDisposition(section.disposition)" :key="question.id">
                <question-type-text v-if="question.type.id === 1" :question="question"
                                    :isPreview="isPreview" @answer="handleAnswer"></question-type-text>
                <question-type-radio v-if="question.type.id ===2" :question="question"
                                     :is-preview="isPreview" @answer="handleAnswer"></question-type-radio>
                <question-type-text-area v-if="question.type.id === 3" :question="question"
                                         :is-preview="isPreview" @answer="handleAnswer"></question-type-text-area>
                <question-type-media v-if="question.type.id === 4" :question="question"
                                     :questionnaire-id="questionnaireId"
                                     :user="user"
                                     @answer="handleAnswer"></question-type-media>
              </div>
            </div>
          </div>
        </b-step-item>
      </b-steps>
    </div>
    <div class="hero-foot">
      <div v-if="!isReview" class="buttons is-right p-6">
        <b-tooltip label="Deshabilitado en previsualización" :active="isPreview"
                   type="is-dark"
                   position="is-top">
          <b-button type="is-dark" @click="saveQuestionnaire" :disabled="isPreview" :loading="waitingForResponses">
            Guardar
            Borrador
          </b-button>
        </b-tooltip>
        <b-tooltip label="Deshabilitado en previsualización" :active="isPreview"
                   type="is-dark"
                   position="is-top">
          <b-button type="is-primary" @click="handleSubmit(sendQuestionnaire)" :loading="waitingForResponses"
                    :disabled="isPreview">Enviar
          </b-button>
        </b-tooltip>
      </div>
      <div v-if="isReview" class="buttons is-right p-6">
        <b-tooltip label="Deshabilitado en previsualización" :active="isPreview"
                   type="is-dark"
                   position="is-top">
          <b-button type="is-dark" @click="cancelReview" :disabled="isPreview"
                    :loading="waitingForResponses">Volver
          </b-button>
        </b-tooltip>
        <b-tooltip label="Deshabilitado en previsualización" :active="isPreview"
                   type="is-dark"
                   position="is-top">
          <b-button type="is-primary" @click="handleSubmit(approveQuestionnaire)" :loading="waitingForResponses"
                    :disabled="isPreview">Aprobar
          </b-button>
        </b-tooltip>
      </div>
    </div>
  </validation-observer>
  <b-loading v-else :is-full-page="false" :v-model="isLoadingState"
             :active="isLoadingState"
             :can-cancel="false">
    <div class="is-flex is-flex-direction-column is-align-items-center">
      <h2 class="title is-2" style="color:black !important;">{{ savingQuestionnaire ? 'Procesando ficha de análisis' :
        'Cargando ficha de análisis'
        }}</h2>
      <b-icon
        pack="fas"
        icon="sync-alt"
        size="is-large"
        custom-class="fa-spin">
      </b-icon>
    </div>
  </b-loading>

</template>

<script>
import QuestionTypeText from '@/components/question/QuestionTypeText'
import QuestionTypeRadio from '@/components/question/QuestionTypeRadio'
import QuestionTypeTextArea from '@/components/question/QuestionTypeTextArea'
import QuestionTypeMedia from '@/components/question/QuestionTypeMedia'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  name: 'DptQuestionnaire',
  components: {
    quillEditor,
    QuestionTypeText,
    QuestionTypeRadio,
    QuestionTypeTextArea,
    QuestionTypeMedia
  },
  props: {
    isPreview: {
      required: true,
      type: Boolean
    },
    isReview: {
      required: false,
      type: Boolean,
      default: false
    },
    questionnaireId: {
      type: Number,
      required: true
    },
    reviewedUserId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      activeSection: 0,
      answers: {},
      receivedResponses: {},
      fetchingQuestionnaire: false,
      openHelp: false,
      questionnaire: null,
      quillOptions: {
        readOnly: true,
        placeholder: 'Inserte el texto de ayuda aquí',
        theme: 'bubble'
      },
      savingQuestionnaire: false,
      waitingForResponses: false

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER,
      userRole: types.GET_ROLE
    }),
    isLoadingState () {
      return this.fetchingQuestionnaire || this.savingQuestionnaire
    }
  },
  created () {
    this.fetchAnswerQuestionnaire()
  },
  methods: {
    approveQuestionnaire () {
      this.$root.$emit('getAnswers')
      this.waitingForResponses = true
      this.$buefy.dialog.confirm({
        title: 'Aprobar Ficha de análisis',
        message:
          '¿Está seguro de que quiere <b>aprobar</b> la ficha de análisis? Esta acción no se puede deshacer',
        confirmText: 'Aprobar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          const eventChecker = setInterval(() => {
            if (this.receivedResponses.length === this.questionnaire.questions.length) {
              this.savingQuestionnaire = true
              this.axios.post(process.env.VUE_APP_API_CREATE_ANSWERS, Object.values(this.answers))
                .then(() => {
                  this.axios.put(process.env.VUE_APP_API_UPDATE_USER_QUESTIONNAIRE_STATUS_APPROVE, {
                    userId: this.reviewedUserId,
                    questionnaireId: this.questionnaireId
                  }).then(() => {
                    this.savingQuestionnaire = true
                    this.$notify.success({
                      title: 'Ficha de análisis aprobado',
                      message: 'La ficha de análisis ha sido aprobado correctamente'
                    })
                    this.$emit('questionnaireApproved')
                  }).catch(err => {
                    console.error('No se puede actualizar el estado del formulario', err)
                    this.savingQuestionnaire = false
                  })
                }).catch(err => {
                  console.error('No se puede aprobar la ficha de análisis', err)
                  this.savingQuestionnaire = false
                })
              clearInterval(eventChecker)
              this.waitingForResponses = false
            }
          }, 50)
        }
      })
    },
    cancelReview () {
      this.$emit('cancelReview')
    },
    fetchAnswerQuestionnaire () {
      this.fetchingQuestionnaire = true
      this.axios.get(process.env.VUE_APP_API_GET_ANSWER_QUESTIONNAIRE, {
        params: {
          questionnaireId: this.questionnaireId,
          userId: this.isReview ? this.reviewedUserId : this.user.id
        }
      }).then(response => {
        if (this.userRole.id > 3) {
          this.questionnaire = this.excludePrivilegedSections(response.data)
        } else {
          this.questionnaire = response.data
        }
      }).finally(() => {
        this.fetchingQuestionnaire = false
      })
    },
    handleAnswer (data) {
      this.receivedResponses[data.questionId] = this.receivedResponses[data.questionId]
        ? this.receivedResponses[data.questionId] + 1 : 1
      this.answers[data.questionId] = {
        questionId: data.questionId,
        answer: data.answer,
        userId: this.user.id,
        questionnaireId: this.questionnaireId
      }
    },
    handleDisposition (disposition) {
      let selectedClass = ''
      switch (disposition.id) {
        case 1:
          selectedClass = 'column is-full'
          break
        case 2:
          selectedClass = 'column is-half'
          break
        case 3:
          selectedClass = 'column is-one-third'
          break
      }
      return selectedClass
    },
    saveQuestionnaire () {
      this.$root.$emit('getAnswers')
      this.waitingForResponses = true
      const eventChecker = setInterval(() => {
        if (this.receivedResponses.length === this.questionnaire.questions.length) {
          this.savingQuestionnaire = true
          this.axios.post(process.env.VUE_APP_API_CREATE_ANSWERS, Object.values(this.answers)).then(() => {
            this.axios.put(process.env.VUE_APP_API_UPDATE_USER_QUESTIONNAIRE_STATUS_DRAFT, {
              userId: this.user.id,
              questionnaireId: this.questionnaireId
            }).then(() => {
              this.savingQuestionnaire = true
              this.$notify.success({
                title: 'Borrador guardado',
                message: 'Se ha guardado un borrador de la ficha de análisis correctamente'
              })
              this.$emit('questionnaireAnswered')
            }).catch(err => {
              console.error('No se puede actualizar el estado del formulario', err)
              this.savingQuestionnaire = false
            })
          }).catch(err => {
            console.error('No se puede guardar la ficha de análisis', err)
            this.savingQuestionnaire = false
          })
          clearInterval(eventChecker)
          this.waitingForResponses = false
        }
      }, 50)
    },
    sendQuestionnaire () {
      this.$root.$emit('getAnswers')
      this.waitingForResponses = true
      this.$buefy.dialog.confirm({
        title: 'Enviar Cuestionario',
        message:
          '¿Está seguro de que quiere <b>enviar</b> la ficha de análisis? Esta acción no se puede deshacer',
        confirmText: 'Enviar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          const eventChecker = setInterval(() => {
            if (this.receivedResponses.length === this.questionnaire.questions.length) {
              this.axios.post(process.env.VUE_APP_API_CREATE_ANSWERS, Object.values(this.answers))
                .then(() => {
                  this.axios.put(process.env.VUE_APP_API_UPDATE_USER_QUESTIONNAIRE_STATUS_SENT, {
                    userId: this.user.id,
                    questionnaireId: this.questionnaireId
                  }).then(() => {
                    this.$notify.success({
                      title: 'Cuestionario enviado',
                      message: 'La ficha de análisis ha sido enviada correctamente'
                    })
                    this.$emit('questionnaireAnswered')
                  })
                })
              clearInterval(eventChecker)
              this.waitingForResponses = false
            }
          }, 50)
        }
      })
    },
    excludePrivilegedSections (questionnaire) {
      questionnaire.sections = questionnaire.sections.filter(s => !s.requiredAccessLevel || s.requiredAccessLevel > 3)
      return questionnaire
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .loading-background {
  background: none !important;
}
.panel-block {
  background: white;
}

</style>
