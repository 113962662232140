<template>
  <div class="block">
    <h5 class="title is-5">{{ question.title }}</h5>
    <p>{{ question.description }}</p>
      <b-field  :key="question.id">
        <b-input value="Respuesta" v-model="answer"></b-input>
      </b-field>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  methods: {
    saveAnswer () {
      this.$emit('answer', {
        questionId: this.question.id,
        answer: this.answer
      })
    }
  },
  created () {
    this.$root.$on('getAnswers', this.saveAnswer)
    if (this.question.answer) {
      this.answer = this.lodash.cloneDeep(this.question.answer)
    }
  },
  data () {
    return {
      answer: ''
    }
  },
  name: 'QuestionTypeText'
}
</script>

<style lang="scss">

</style>
