<template>
  <div class="block">
    <h5 style="position: relative" class="title is-5">{{ question.title }}</h5>

    <p>{{ question.description }}</p>
    <b-field>
      <b-select placeholder="Seleccione una opción" v-model="answer" expanded>
        <option v-for="(option, i) in question.options" :value="option.id" :key="i">{{ option.name }}</option>
      </b-select>
    </b-field>
    <b-field v-if="isPreview" type="is-warning" horizontal label="Supervisor:" class="mt-3">
      <b-input type="textarea" size="is-small" :disabled="true" placeholder="Comentario del supervisor" maxlength="200"
               v-model="comment"></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    isPreview: {
      type: Boolean,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      comment: 'Comentario enviado por el supervisor',
      answer: ''
    }
  },
  methods: {
    saveAnswer () {
      this.$emit('answer', {
        questionId: this.question.id,
        answer: this.answer
      })
    }
  },
  created () {
    this.$root.$on('getAnswers', this.saveAnswer)
    if (this.question.answer) {
      this.answer = this.lodash.cloneDeep(this.question.answer)
    }
  },
  name: 'QuestionTypeRadio'
}
</script>

<style lang="scss" scoped>

</style>
